<template>
    <div>
        <Preloader v-if='loading' />
        <div class='row'>
            <div class='col-5 campaign__form'>
                <h3>Новая кампания</h3>
                <b-form @submit.prevent='handleSubmit'>
                    <b-form-group
                        id='message'
                        description='Шаблон сообщения, находится в таблице справа, чтобы его выбрать необходимо кликнуть на сооответсвующий шаблон'
                        label='Шаблон сообщения'
                        label-for='input-1'
                        valid-feedback='Thank you!'
                    >
                        <b-form-input id='message' required v-model='form.messageLabel' disabled :state='messageState'
                                      trim></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id='message'
                        description='	Дата и время запуска рассылки. Если не задано, то рассылка начинается немедленно.'
                        label='Дата начала'
                        label-for='input-1'
                        valid-feedback='Thank you!'
                    >
                        <b-form-datepicker id='message' v-model='form.date' trim></b-form-datepicker>
                    </b-form-group>

                    <b-form-group
                        id='message'
                        description='	Дата и время запуска рассылки. Если не задано, то рассылка начинается немедленно.'
                        label='Время начала'
                        label-for='input-1'
                        valid-feedback='Thank you!'
                    >
                        <b-form-timepicker id='message' v-model='form.time' trim></b-form-timepicker>
                    </b-form-group>


                    <b-form-group
                        id='message'
                        description='Часовой пояс, Если не указан, то используется часовой пояс из настроек Личного Кабинета.'
                        label='Часовой пояс'
                        label-for='input-1'
                        valid-feedback='Thank you!'
                        :invalid-feedback='messageFeedback'
                        :state='messageState'
                    >
                        <b-form-select
                            id='utc'
                            class=''
                            :options='getAllTimezones'
                            v-model='form.timezone'
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id='track_label'
                        description='по умолчанию не отслеживать'
                        label='Отслеживать факт прочтения письма?'
                        label-for='track_read'
                        valid-feedback='Thank you!'
                    >
                        <b-form-checkbox
                            id='track_read'
                            class=''
                            v-model='form.track_read'
                        ></b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                        id='track_link'
                        description='по умолчанию не отслеживать'
                        label='Отслеживать переходы по ссылкам в e-mail?'
                        label-for='track_links'
                        valid-feedback='Thank you!'
                    >
                        <b-form-checkbox
                            id='track_links'
                            class=''
                            v-model='form.track_links'
                        ></b-form-checkbox>
                    </b-form-group>

                    <b-button type='submit' variant='primary'>Создать и запустить</b-button>
                </b-form>
            </div>
            <div class='col-7 campaign__messages'>
                <div class='message__controls text-right mb-4'>
                    <button class='button_add btn__add mr-3' @click='addData'>
                        <i class='fa fa-plus' aria-hidden='true'></i> Создать шаблон сообщения
                    </button>
                </div>
                <EmaiMessageList
                    class='widget'
                    :messages='messages'
                    :click-handler='pickMessage'
                />
            </div>
        </div>
    </div>
</template>

<script>
import EmaiMessageList from '../../components/EmaiMessageList';
import Preloader from '../../components/Preloader';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import moment from 'moment';
import messages from '../../components/Notifications/NotificationsDemo/Messages';

export default {
    name: 'EmailCampaignCreate',
    components: { Preloader, EmaiMessageList },
    data() {
        return {
            loading: false,
            messages: null,
            editMessageMode: false,
            form: {
                messageLabel: null,
                message: null,
                timezone: null,
                date: null,
                time: null,
                track_read: false,
                track_links: false,
                contacts: null,
            },
        };
    },
    computed: {
        messageState() {
            return this.form.messages ? this.form.messages.length > 0 : false;
        },
        messageFeedback() {
            return 'необходимо выбрать шаблон сообщений';
        },
        ...mapGetters({
            getAllTimezones: 'common/getAllTimezones',
        }),
    },
    methods: {
        pickMessage(id, subject) {
            this.form.message = id;
            this.form.messageLabel = subject;
        },
        async fetchMessages() {
            try {
                const promiseList = (() => {
                    let res = [this.$axios.get('api/mailing/email/message')];
                    if (this.editMessageMode) {
                        res.push(this.$axios.get(`api/mailing/email/message/${this.$route.params.id}`));
                    }
                    return res;
                })();
                const [allMessages, currentMessage] = await Promise.all(promiseList);
                const { data } = allMessages;

                if (currentMessage && currentMessage.data && currentMessage.error) {
                    throw currentMessage['message']
                }

                if (currentMessage && currentMessage.data && currentMessage.data.message) {
                    const message = currentMessage.data.message
                    console.log(',,,,,,,,,,,,,,', message);
                    this.form.message = message.id;
                    this.form.messageLabel = message.subject;

                    const time = new moment(message.created);
                    console.log(time);

                    this.form.time = time.format('H:m:s')
                    this.form.date = time.format('YYYY-MM-DD')
                    // this.form = message.messageLabel
                }

                if (data && data.result) {
                    this.messages = data.result;
                }
                console.log(data);
            } catch (e) {
                console.log(e);
                this.$toasted.error('Произошла ошибка при загрузке', {
                    position: 'bottom-right',
                });
            }
        },
        async addData() {
            await this.$router.push({ name: 'EmailCreate' });
        },
        toggleLoading() {
            this.loading = !this.loading;
        },
        async handleSubmit() {
            this.toggleLoading();
            try {
                let datetime;
                if (this.form.date && this.form.time) {
                    datetime = new dayjs(this.form.date + ' ' + this.form.time).format('DD.MM.YYYY hh:mm');
                }

                const createPayload = () => {
                    const reqs = ['message', 'timezone', 'track_read', 'track_links'];
                    const payload = {};
                    for (const item of reqs) {
                        payload[item] = this.form[item];
                    }
                    return payload;
                };

                const { data } = await this.$axios.post('api/mailing/email/campaign', {
                    datetime,
                    ...createPayload(),
                });

                if (data && data.error) {
                    this.$toasted.error('произошла ошибка при запуске кампании', { position: 'bottom-right' });
                    return;
                }

                if (data && data.result) {
                    this.$toasted.success('Кампания создана', { position: 'bottom-right' });
                    return;
                }

            } catch (e) {
                this.$toasted.error('произошла ошибка запроса', { position: 'bottom-right' });
            }
            this.toggleLoading();
        },
    },
    async created() {
        if (this.$route.name === 'EmailCampaignEdit' && this.$route.params.id) {
            this.editMessageMode = true;
        }
        await this.fetchMessages();
    },
};
</script>

<style scoped lang='scss'>
.btn__add {
    height: 30px;
    //float: right;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    //border: 1px solid #333;
    background: none;
}
</style>